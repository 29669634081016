import { Button, Container, Grid, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, Slide  } from '@mui/material';
import {makeStyles} from '@material-ui/styles';
import React, { useState, useEffect, useRef } from 'react'
import qrScannerImg from '../assets/img/Icon/qr-scanner.png'
import QrScanner from 'qr-scanner'
import axios from 'axios'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    page: {
        textAlign: "center",
        height: "100%",
        overflow: "hidden",
        position: "relative"
        // height: "100vh",
        // width: '100%',
        // // marginTop: 30,
        // backgroundColor: "#FAFAFA",
        // position: "relative"
    },
    applayer: {
        // marginTop:30
        position: "fixed",
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    overlay: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        margin: 'auto',
        height: 300,
        // width: 300,
        position: "fixed",
        transition: 'all 200ms ease-in'
    },
    qrImg: {
        zIndex: 1,
        position: 'relative',
        height: 300,
        width: 300
    },
    customScanner: {
        width: 240,
        height: 2,
        background: 'red',
        position: 'absolute',
        transition: 'all 200ms linear',
        animation: 'scanner 3s infinite linear',
        WebkitAnimation: 'scanner 3s infinite linear',
        WebkitTransition: 'all 200ms linear',
        // boxShadow: '0px 0px 7px 10px rgba(170, 11, 23, 0.49)',
        zIndex: 2,
        left: 0,
        right: 0,
        margin: 'auto',
    },
    video: {
        transform: "translateX(-50%) translateY(-50%) !important",
        top: "50%",
        left: "50%",
        minWidth: "100%",
        minHeight: "100%",
        width: "auto",
        height: "auto",
        position: "absolute",
    },
    cardContainer: {
        zIndex: 99,
        marginRight: 15,
        marginLeft: 15
    },
    img: {
        height: 200,
        // width: 230,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
    },
}));

function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}


const QRScanner = () => {
    const classes = useStyles();
    const videoRef = useRef();
    var qrScanner = useRef(null);
    var [open, setOpen] = useState(false);
    var [dialogText, setDialogText] = useState('');
    var [dialogTitle, setDialogTitle] = useState('');

    var handleScan = (result)=>{
        qrScanner.current.stop();
        if (isValidHttpUrl(result.data)){
            window.location.href = result.data;
        }else{
            axios.get(`${window.location.origin}/api/payment`,{
                params : {
                    qrCodeText : result.data,
                    simResult : 1
                }
            })
            .then((response)=>{
                console.log(response.data)
                if (response.data.result.result === "Success"){
                    handleDialog(true, JSON.stringify(response.data));
                }
                else{
                    handleDialog(false, JSON.stringify(response.data));
                }
            })
            .catch((error)=>{
                console.log(error);
                handleDialog(false, error.data);
            })
        }
    }

    var handleClose = ()=>{
        setOpen(false);
        qrScanner.current.start();
    }

    var handleDialog = (open, text = '')=>{
        setDialogText(`URL: ${text}`);
        setOpen(true);
        if (open){
            setDialogTitle(`Success`)
            
        }
        else{
            setDialogTitle(`Failed`)
        }        
    }

    useEffect(() => {
        qrScanner.current = new QrScanner(videoRef.current, result => {
            handleScan(result)
        }, { returnDetailedScanResult: true });
    }, [])

    useEffect(() => {
        qrScanner.current.start();
    }, [qrScanner])


    return (
        <div className={classes.page}>

            <Grid container justifyContent="flex-end" className={classes.applayer}>
                <Container >
                    <video ref={videoRef} className={classes.video} />
                </Container>
            </Grid>

            <Container className={classes.overlay}>
                <Grid>
                    <img src={qrScannerImg} className={classes.qrImg} id="scanner" />
                </Grid>
            </Container>

            <Dialog open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    {dialogText}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default QRScanner
