import './App.css';
import QRScanner from './page/qrScanner';

function App() {
  return (
    <div className="App">
      <QRScanner></QRScanner>
    </div>
  );
}

export default App;
